<template>
  <div class="parameter-main">
    <div class="parameter-title">
      <span>参数说明:</span>
      <div class="parameter-box">
        <div>{{ title }}</div>
        <img
          :class="['arrow', { 'arrow-expand': !isExpand }]"
          src="@/assets/images/appLications/arrow.png"
          alt="图片"
          @click="isExpand = !isExpand"
        />
      </div>
    </div>

    <div
      :class="['parameter-lines', { 'parameter-lines-isExpand': isExpand }]"
      ref="parameterLines"
    >
      <div class="lines-header">
        <div class="left">名称</div>
        <div class="centre">类型</div>
        <div class="right">描述</div>
      </div>
      <div
        class="lines-box"
        v-for="(item, index) in parameterList"
        :style="{ background: index % 2 !== 0 ? '#F4F4F4' : '' }"
        :key="index"
      >
        <div class="left">{{ item.name }}</div>
        <div class="centre">{{ item.type }}</div>
        <div class="right">
          <p v-for="dItem in item.describe" :key="dItem">{{ dItem }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jumpParameters } from "@/utils/data";
export default {
  props: ["parameterList", "title"],
  data() {
    return {
      isExpand: true, //是否展开
      // parameterList: jumpParameters,
    };
  },
};
</script>

<style lang="scss" scoped>
.parameter-main {
  width: 100%;
  .parameter-title {
    display: flex;
    align-items: center;
    span {
      color: #666666;
      font-size: 14px;
      width: 94px;
    }
    .parameter-box {
      width: 100%;
      border: 1px solid #dddddd;
      position: relative;
      height: 36px;
      line-height: 36px;
      background: #f0f0f0;
      border-radius: 4px;
      div {
        font-size: 14px;
        margin-left: 22px;
      }
      .arrow {
        position: absolute;
        right: 22px;
        top: 10px;
        width: 16px;
        height: 16px;
        transform: rotate(0);
        transition: transform 0.3s;
        cursor: pointer;
      }
      .arrow-expand {
        transform: rotate(-180deg);
      }
    }
  }
}
.parameter-lines {
  padding-left: 86px;
  margin-top: 10px;
  max-height: 0;
  transition: max-height 0.3s;
  overflow: hidden;
}
.parameter-lines-isExpand {
  max-height: 800px;
}
.lines-header {
  display: flex;
  height: 36px;
  line-height: 36px;
  border: 1px solid #dddddd;
  color: #06052b;
  font-size: 14px;
  border-radius: 4px 4px 0 0;
  .left {
    font-weight: bold;
    width: 150px;
    padding-left: 22px;
  }
  .centre {
    font-weight: bold;
    width: 150px;
    padding-left: 22px;
  }
  .right {
    font-weight: bold;
    padding-left: 22px;
  }
}
.lines-box {
  display: flex;
  min-height: 36px;
  border: 1px solid #dddddd;
  border-top: none;
  color: #06052b;
  font-size: 14px;
  .left {
    width: 150px;
    padding-left: 22px;
    display: flex;
    align-items: center;
  }
  .centre {
    width: 150px;
    padding-left: 22px;
    display: flex;
    align-items: center;
  }
  .right {
    padding-left: 22px;
    width: calc(100% - 366px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .no-wrap {
    white-space: nowrap;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>