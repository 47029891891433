<template>
  <div class="setting-main" v-if="appDetails">
    <Breadcrumb :breadcrumbList="list"></Breadcrumb>
    <!-- 常规设置 -->
    <div class="general-settings">
      <div class="essential_information">
        <img class="left" :src="appType.img" alt="图片" />
        <div class="right">
          <div class="name-box">
            <div class="name">{{ appDetails.name }}</div>
            <div class="type">行业类型：{{ appIndustryId.label }}</div>
          </div>
          <div class="key-box">
            <div class="key">
              app Key：<span>{{ appDetails.appKey }}</span>
            </div>
            <img
              src="@/assets/images/appLications/copy.png"
              @click="copyContent(appDetails.appKey)"
              alt="图片"
            />
          </div>
          <div class="key-box">
            <div class="key">
              security Key：<span>{{ appDetails.securityKey | getKey }}</span>
            </div>
            <img
              src="@/assets/images/appLications/copy.png"
              @click="copyContent(appDetails.securityKey)"
              alt="图片"
            />
          </div>
        </div>
      </div>

      <div class="title">常规设置</div>

      <div class="jump-setting">
        <div class="jump-name">跳转页面设置</div>
        <div class="tips-box">
          <img src="@/assets/images/appLications/tips.png" alt="图片" />
          <span>当问卷答题结束后，跳转到以下指定地址</span>
        </div>
      </div>

      <div class="input-box">
        <div class="input-title">完成：</div>
        <el-input
          v-model="redirects.complete"
          placeholder="例如：https：*************"
          maxlength="1000"
        ></el-input>
      </div>
      <div class="input-box">
        <div class="input-title">甄别：</div>
        <el-input
          v-model="redirects.screenOut"
          placeholder="请输入****"
          maxlength="1000"
        ></el-input>
      </div>
      <div class="input-box">
        <div class="input-title">配额满：</div>
        <el-input
          v-model="redirects.overQuota"
          placeholder="请输入****"
        ></el-input>
      </div>
      <div class="input-box">
        <div class="input-title">质量终止：</div>
        <el-input
          v-model="redirects.qualityScreenOut"
          placeholder="请输入****"
          maxlength="1000"
        ></el-input>
      </div>
      <el-button class="save" @click="setRedirects">保存</el-button>
      <div class="parameter-description">
        <ParameterDescription
          :parameterList="jumpParameters"
          :title="'传入参数'"
        ></ParameterDescription>
      </div>

      <div class="switch-settings">
        <div class="switch-name">项目开/关设置</div>
        <div class="tips-box">
          <img src="@/assets/images/appLications/tips.png" alt="图片" />
          <span
            >此处设置问卷，暂停结束，重启等状态变更接口，以便及时反馈给合作平台变更项目状态。</span
          >
        </div>
      </div>
      <div class="url-box">
        <div class="url-title">问卷关闭：</div>
        <el-input
          v-model="projS2S.opened"
          maxlength="1000"
          placeholder="请输入问卷状态变化地址"
        ></el-input>
        <el-button @click="setProjS2S">应用</el-button>
      </div>
      <div class="parameter-description">
        <ParameterDescription
          :title="'传入参数'"
          :parameterList="jsonProjectSwitchCodes"
        ></ParameterDescription>
      </div>
    </div>

    <!-- 预览设置 -->
    <div class="preview-settings">
      <div class="preview-title">调查墙设置</div>
      <div class="illustrate">
        <img src="@/assets/images/appLications/tips.png" alt="图片" />
        <span>调查墙方式用于直接展示各个可用问卷调查列表</span>
      </div>
      <div class="preview-fa" v-if="walls">
        <Preview
          :walls="walls"
          :appId="appId"
          :wallsIsShow="wallsIsShow"
          :appKey="appDetails.appKey"
        ></Preview>
      </div>
      <div class="explain">
        复制以下链接，可以设置在您指定的页面即可快速展示出属于您的问卷调查墙。
      </div>
      <div class="input-box">
        <el-input
          v-model="WallLink"
          placeholder="Https:1234567890.com"
          maxlength="1000"
        ></el-input>
        <el-button @click="copyContent(WallLink)">复制</el-button>
      </div>
    </div>

    <!-- json调查墙 -->
    <div class="json-settings">
      <div class="json-title">JSON调查墙</div>
      <div class="introduce">
        复制以下链接，即可获得JSON格式下的问卷列表数据流，您可以根据您的需求任意排版，从而达到高度集成到自有平台；
      </div>
      <div class="input-box">
        <el-input
          v-model="jSONLink"
          placeholder="Https:1234567890.com"
          maxlength="1000"
        ></el-input>
        <el-button @click="copyContent(jSONLink)">复制</el-button>
      </div>
      <div class="json-code">JSON代码</div>
      <div class="example-code">示例代码</div>
      <pre v-highlight class="pre-highlight">
      <code class="json">{{watch_data}}</code>
      <img class="copy" @click="copyContent(watch_data)" src="@/assets/images/appLications/copy.png" alt="图片" />
     </pre>
      <div class="parameter-description">
        <ParameterDescription
          :title="'传入参数'"
          :parameterList="jsonAfferentCodes"
        ></ParameterDescription>
        <div class="interval"></div>
        <ParameterDescription
          :title="'响应参数'"
          :parameterList="jsonResponseCodes"
        ></ParameterDescription>
      </div>
      <div class="footer-fa">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
import ParameterDescription from "./internalComponents/parameterDescription.vue";
import Footer from "@/components/Footer/homePageFooter.vue";
import Preview from "./internalComponents/preview.vue";
import Hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css"; //样式
import {
  IndustryTypes,
  applicationAppTypes,
  jumpParameters,
  sampleCode,
  jsonAfferentCodes,
  jsonResponseCodes,
  jsonProjectSwitchCodes,
} from "@/utils/data";
import {
  getAppsDetails,
  getRedirects,
  setRedirects,
  getProjS2S,
  setProjS2S,
  getAppWall,
} from "@/api/app";
export default {
  components: {
    Breadcrumb,
    ParameterDescription,
    Footer,
    Preview,
  },
  filters: {
    //key显示部分
    getKey(value) {
      let start = value.substring(0, 5);
      let end = value.substring(value.length - 5, value.length);
      value = start + "***" + end;
      return value;
    },
  },
  data() {
    return {
      list: [
        {
          name: "应用列表",
          path: null,
        },
        {
          name: null,
          path: "/application",
        },
        {
          name: "应用设置",
          path: null,
        },
      ],
      tips: "这是一段内容提示这是一段内容提示这是一段内容提示",
      value: "",
      watch_data: sampleCode,
      jumpParameters: jumpParameters,
      jsonAfferentCodes: jsonAfferentCodes,
      jsonResponseCodes: jsonResponseCodes,
      jsonProjectSwitchCodes: jsonProjectSwitchCodes,
      appId: null, //应用id
      appDetails: null, //应用详情
      appIndustryId: null, //行业类型
      appType: null, //应用类型
      redirects: {
        complete: "",
        overQuota: "",
        qualityScreenOut: "",
        screenOut: "",
      }, //跳转页面设置
      projS2S: {
        closed: "",
        opened: "",
      }, //项目开/关设置

      walls: null, //调查墙设置
      wallsIsShow: false,
      WallLink: null, //调查墙设置链接
      jSONLink: null, //JSON设置链接
    };
  },
  directives: {
    highlight(el) {
      let blocks = el.querySelectorAll("pre code");
      for (let i = 0; i < blocks.length; i++) {
        const item = blocks[i];
        Hljs.highlightBlock(item);
      }
    },
  },
  created() {
    this.appId = this.$route.query.id;
    this.getAppsDetails();
    this.getRedirects();
    this.getProjS2S();
    this.getAppWall();
  },
  methods: {
    //复制
    copyContent(data) {
      if (typeof data === "object") {
        data = JSON.stringify(data);
      }

      this.$copyText(data).then(
        () => {
          this.$message.success("复制成功");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    //获取应用详情
    getAppsDetails() {
      getAppsDetails(this.appId).then((res) => {
        if (res.status === 200) {
          this.appDetails = res.data;
          console.log("应用详情", res.data);
          this.list[1].name = this.appDetails.name;
          this.appIndustryId = IndustryTypes.find((item) => {
            return item.value === this.appDetails.industryId;
          });
          this.appType = applicationAppTypes.find((item) => {
            return item.id === res.data.cateId;
          });
          this.getLinkProview();
        }
      });
    },
    //获取跳转页面设置
    getRedirects() {
      getRedirects(this.appId).then((res) => {
        console.log("获取跳转页面设置", res.data);
        if (res.status === 200 && res.data) {
          this.redirects = res.data;
        }
      });
    },
    //设置跳转页面设置
    setRedirects() {
      this.redirects.appId = this.appId;
      setRedirects(this.redirects).then((res) => {
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: "保存成功",
            type: "success",
          });
        }
      });
    },
    //获取项目开/关设置
    getProjS2S() {
      getProjS2S(this.appId).then((res) => {
        if (res.status === 200 && res.data) {
          this.projS2S = res.data;
        }
      });
    },
    //项目开/关设置
    setProjS2S() {
      this.projS2S.appId = this.appId;
      setProjS2S(this.projS2S).then((res) => {
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: "应用成功",
            type: "success",
          });
        }
      });
    },
    //获取调查墙设置
    getAppWall() {
      getAppWall(this.appId).then((res) => {
        console.log("获取调查墙设置", res);
        if (res.status === 200) {
          this.walls = res.data;
          console.log(1234, this.walls);
          this.wallsIsShow = true;
        }
      });
    },
    //获取预览跳转链接
    getLinkProview() {
      this.jSONLink =
        process.env.VUE_APP_SERVER +
        "api/v1/Apps/" +
        this.appDetails.appKey +
        `/AvailableSurveys?user_id=${
          this.appType.id === 5 ? "" : "替换贵方会员唯一ID"
        }`;

      let href = location.href.substring(
        0,
        location.href.indexOf("application")
      );
      this.WallLink =
        href +
        "preview/?appKey=" +
        this.appDetails.appKey +
        `&user_id=${this.appType.id === 5 ? "" : "替换贵方会员唯一ID"}`;
      console.log("herf", href);
    },
  },
};
</script>
<style lang="scss" scoped>
.setting-main {
  padding: 20px 0 0 20px;
}
.general-settings {
  width: 100%;
  background-color: #fcfcfc;
  margin-top: 20px;
  padding-top: 16px;
  padding-bottom: 73px;
  .essential_information {
    display: flex;
    margin-left: 28px;
    color: #06052b;
    .left {
      width: 64px;
      height: 64px;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 40px;
      .name-box {
        display: flex;
        margin-top: 4px;
        margin-bottom: 5px;
        .name {
          font-weight: bold;
          margin-right: 57px;
        }
        .type {
          font-size: 14px;
        }
      }
      .key-box {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        margin-top: 5px;
        font-size: 14px;
        img {
          width: 16px;
          height: 16px;
          margin: 0 41px 0 15px;
          cursor: pointer;
        }
        span {
          color: #666;
          margin-left: 8px;
        }
      }
    }
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #4578ff;
    margin: 60px 0 0 20px;
  }
  .jump-setting {
    margin: 33px 0 0 87px;
    .jump-name {
      color: #4578ff;
      font-size: 14px;
    }
    .tips-box {
      display: flex;
      align-items: center;
      margin-top: 20px;
      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
      span {
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .input-box {
    display: flex;
    align-items: center;
    padding-right: 173px;
    padding-left: 73px;
    margin-top: 20px;
    .input-title {
      width: 80px;
      font-size: 14px;
      text-align: right;
      margin-right: 14px;
    }
    .el-input {
      /deep/ .el-input__inner {
        height: 36px;
      }
    }
  }
  .save {
    float: right;
    width: 84px;
    height: 34px;
    padding: 0;
    background: #4578ff;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 173px;
    margin-top: 30px;
    margin-bottom: 45px;
  }
  .parameter-description {
    clear: both;
    padding-left: 75px;
    padding-right: 175px;
  }
  .switch-settings {
    margin: 100px 0 0 87px;
    .switch-name {
      color: #4578ff;
      font-size: 14px;
    }
    .tips-box {
      display: flex;
      align-items: center;
      margin-top: 20px;
      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
      span {
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .url-box {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 20px;
    width: calc(100% - 100px);
    .url-title {
      width: 88px;
      font-size: 14px;
      color: #666666;
      margin-left: 73px;
    }
    .el-input {
      width: calc(100% - 337px);
      /deep/ .el-input__inner {
        height: 36px;
      }
    }
    .el-button {
      width: 84px;
      height: 34px;
      padding: 0;
      background: #4578ff;
      color: #fff;
      margin-left: 18px;
    }
  }
}
.preview-settings {
  width: 100%;
  background-color: #fcfcfc;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 57px;
  .preview-title {
    font-size: 16px;
    font-weight: bold;
    color: #4578ff;
    margin-left: 20px;
  }
  .illustrate {
    width: calc(100% - 316px);
    margin-left: 157px;
    margin-top: 12px;
    height: 91px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #4578ff;
    img {
      width: 12px;
      height: 12px;
      margin-right: 10px;
    }
  }
  .preview-fa {
    margin: 0 157px;
    margin-top: 40px;
  }
  .explain {
    color: #9091a1;
    font-size: 14px;
    margin-top: 74px;
    margin-left: 157px;
  }
  .input-box {
    display: flex;
    margin-top: 40px;
    .el-input {
      margin-left: 157px;
      width: calc(100% - 414px);
      /deep/ .el-input__inner {
        height: 36px;
      }
    }
    .el-button {
      width: 84px;
      height: 34px;
      padding: 0;
      background: #4578ff;
      border-radius: 4px;
      color: #fff;
      margin-right: 157px;
      margin-left: 17px;
    }
  }
}

.json-settings {
  width: 100%;
  background-color: #fcfcfc;
  margin-top: 20px;
  padding-top: 20px;
  .json-title {
    font-size: 16px;
    font-weight: bold;
    color: #4578ff;
    margin-left: 20px;
  }
  .introduce {
    margin-left: 157px;
    margin-top: 20px;
    font-size: 14px;
    color: #9091a1;
  }
  .input-box {
    display: flex;
    margin-top: 40px;
    .el-input {
      margin-left: 157px;
      width: calc(100% - 414px);
      /deep/ .el-input__inner {
        height: 36px;
      }
    }
    .el-button {
      width: 84px;
      height: 34px;
      padding: 0;
      background: #4578ff;
      border-radius: 4px;
      color: #fff;
      margin-right: 157px;
      margin-left: 17px;
    }
  }
  .json-code {
    margin-left: 157px;
    margin-top: 60px;
    font-size: 14px;
    font-weight: bold;
  }
  .example-code {
    font-size: 14px;
    margin-left: 157px;
    margin-top: 30px;
  }
  .pre-highlight {
    margin-left: 157px;
    margin-right: 157px;
    max-height: 320px;
    position: relative;
    /deep/ .json {
      max-height: 320px;
    }
    .copy {
      position: absolute;
      top: 50px;
      right: 35px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .parameter-description {
    margin-left: 158px;
    padding-right: 158px;
    margin-top: 100px;
    .interval {
      margin: 40px 0;
    }
    .parameter-description {
      margin-left: 0;
      font-size: 14px;
      color: #9091a1;
      margin-bottom: 20px;
    }
  }
  .footer-fa {
    padding-right: 20px;
    background-color: #fff;
    /deep/ .home-page-footer {
      margin-bottom: 0 !important;
      padding-bottom: 25px;
    }
  }
}
</style>