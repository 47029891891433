<template>
  <div class="preview-main" v-if="previewWalls">
    <!-- 设置 -->
    <div class="setup-content">
      <div class="title">基本设置</div>
      <div class="illustrate">
        <div class="illustrate-title">积分单位名称</div>
        <el-popover
          placement="top-start"
          title="说明"
          width="200"
          trigger="hover"
          content="积分墙显示积分名称，如：金币、元宝、元等。"
        >
          <img
            slot="reference"
            class="tips"
            src="@/assets/images/appLications/tips.png"
            alt="图片"
          />
        </el-popover>
      </div>
      <el-input
        v-model="previewWalls.unitName"
        maxlength="3"
        placeholder="请输入积分单位名称 "
      ></el-input>
      <div class="exchange">
        <div class="exchange-title">
          <span class="bold">汇率设置</span>
          <el-popover
            placement="top-start"
            title="说明"
            width="200"
            trigger="hover"
            content="人民币兑换贵方积分数量，如：1元=100积分，则汇率为1人民币=1。1元=10积分，则汇率为1人民币=10。以此类推。"
          >
            <img
              slot="reference"
              class="tips"
              src="@/assets/images/appLications/tips.png"
              alt="图片"
            />
          </el-popover>
        </div>
        <div class="haunsuan">
          <span>1人民币=</span>
          <el-input
            v-model="previewWalls.ratio"
            placeholder="输入值"
            maxlength="4"
            @input="inputChange(1)"
          ></el-input>
        </div>
      </div>
      <div class="exchange">
        <div class="exchange-title">
          <span class="bold">分配比例</span>
          <el-popover
            placement="top-start"
            title="说明"
            width="200"
            trigger="hover"
            content="分配给己方会员的奖励比例。如果问卷价格为5元，分配比例为40，则按照5*40%，己方会员完成全部问卷获得2元。填写范围为0-100。"
          >
            <img
              slot="reference"
              class="tips"
              src="@/assets/images/appLications/tips.png"
              alt="图片"
            />
          </el-popover>
        </div>
        <div class="haunsuan">
          <el-input
            v-model.number="previewWalls.scale"
            placeholder="输入值"
            maxlength="4"
            @input="changeScale"
          ></el-input>
        </div>
      </div>
      <div class="line"></div>
      <div class="minimum">
        <div class="minimum-title">
          <div>设置最低金额问卷</div>
          <el-switch
            @change="changeIsMinimum"
            v-model="previewWalls.minCpiEnabled"
          ></el-switch>
        </div>
        <div class="haunsuan">
          <span>最低应用货币金额</span>
          <el-input
            v-model="previewWalls.minCpi"
            placeholder="输入值"
            @input="inputChange(2)"
          ></el-input>
        </div>
      </div>

      <div class="difficulty">
        <div>是否显示难易度</div>
        <el-switch v-model="previewWalls.IRVisible"></el-switch>
      </div>
      <div class="difficulty">
        <div>是否显示会员奖励</div>
        <el-switch v-model="previewWalls.memberRewardVisible"></el-switch>
      </div>
      <el-button class="save" @click="setAppWall(1)">保存</el-button>
      <div class="straight"></div>
      <div class="title">LOGO设置</div>

      <el-upload
        action="#"
        :class="['card-upload', { 'is-file-list': this.fileImg }]"
        list-type="picture-card"
        :auto-upload="false"
        :on-change="beforeUpload"
        :http-request="setLogoUpload"
        ref="uploadRef"
      >
        <div slot="default" class="default">
          <div>支持JPG、PNG格式可<span>预览</span></div>
          <div>建议大小不超过1000kb，尺寸为331*166</div>
        </div>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <el-button class="upload" @click="submitUpload">上传</el-button>

      <div class="title">外观设置</div>
      <div class="bg-color">
        <div class="bg-title">背景颜色设置</div>
        <el-color-picker
          v-model="previewWalls.backgroundColor"
          @change="
            changeColor(
              '--background-color-preview',
              previewWalls.backgroundColor
            )
          "
        ></el-color-picker>
      </div>
      <div class="font-color">
        <div class="font-title">字体颜色设置</div>
        <el-color-picker
          v-model="previewWalls.fontColor"
          @change="changeColor('--font-color-preview', previewWalls.fontColor)"
        ></el-color-picker>
      </div>
    </div>
    <!-- 预览 -->
    <div class="preview-content">
      <div class="title">预览</div>
      <div class="questionnaire-main">
        <div class="preview-logo">
          <img v-if="previewWalls.logo" :src="previewWalls.logo" alt="LOGO" />
          <img v-else src="@/assets/images/logo-multicolour.png" alt="LOGO" />
        </div>
        <div class="questionnaire-title">
          <div>问卷列表</div>
          <img src="@/assets/images/appLications/questionnaire.png" alt />
        </div>
        <div class="questionnaire-list">
          <div
            class="questionnaire-item"
            v-for="(item, index) in questionnaireList"
            :key="index"
            v-show="item.isShow"
          >
            <div class="number-maxPoints">
              <div class="number">
                <div class="identifier">{{ item.number }}</div>
                <div class="title">{{ item.name }}</div>
              </div>
              <div
                class="maxPoints"
                ref="maxPoints"
                :style="{ fontSize: font ? '' : `${fontSize}px` }"
                v-show="previewWalls.memberRewardVisible"
              >
                {{ item.price | getRmb(previewWalls.ratio, previewWalls.scale)
                }}<span>{{ previewWalls.unitName }}</span>
              </div>
            </div>

            <div class="facility-value" v-show="previewWalls.IRVisible">
              <div class="value">难易度</div>
              <div class="progress-ga">
                <div class="progress-fa">
                  <el-progress
                    :percentage="item.ir"
                    color="#2E67EA"
                    :show-text="false"
                  />
                </div>
                <div class="percentage">{{ item.ir }}%</div>
              </div>
            </div>

            <div class="clock-join">
              <div class="clock-box">
                <img
                  class="clock"
                  src="@/assets/images/appLications/clock.png"
                  alt="图片"
                />
                <span>{{ item.length }}分钟</span>
              </div>
              <div class="join">进入</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setLogoUpload, setAppWall, getAvailableSurveys } from "@/api/app";
import { jsonAfferentCodes } from "@/utils/data";
export default {
  props: ["walls", "appId", "wallsIsShow", "appKey"],
  filters: {
    getRmb(value, number, scale) {
      let data = (value * number * scale) / 100;
      data = data.toFixed(2);
      data = parseFloat(data);
      return data;
    },
  },
  watch: {
    "previewWalls.unitName": {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.$nextTick(() => {
            this.font = this.$refs.maxPoints.every((item) => {
              return item.innerText.length <= 6;
            });
            if (!this.font) {
              this.fontSize = 14;
            }
            const font2 = this.$refs.maxPoints.every((item) => {
              return item.innerText.length <= 7;
            });
            if (!font2) {
              this.fontSize = 12;
            }
          });
        }, 1000);
      },
      immediate: true,
      deep: true,
    },
    "previewWalls.ratio": {
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.font = this.$refs.maxPoints.every((item) => {
            return item.innerText.length <= 6;
          });
          if (!this.font) {
            this.fontSize = 14;
          }
          const font2 = this.$refs.maxPoints.every((item) => {
            return item.innerText.length <= 7;
          });
          if (!font2) {
            this.fontSize = 12;
          }
        }, 1000);
      },
      immediate: true,
      deep: true,
    },
    "previewWalls.backgroundColor": {
      handler() {
        if (this.wallsIsShow) {
          this.setAppWall();
        }
      },
      deep: true,
    },
    "previewWalls.fontColor": {
      handler() {
        if (this.wallsIsShow) {
          this.setAppWall();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      font: true,
      fontSize: 14,
      timer: null,
      fileImg: "",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      questionnaireList: [],
      previewWalls: null,
    };
  },
  mounted() {
    this.getAvailableSurveys();
    this.previewWalls = JSON.parse(JSON.stringify(this.walls));
    console.log("a11", this.previewWalls);
    //  if (this.previewWalls.minCpiEnabled) {
    //     this.getNewList();
    //   }
    if (this.previewWalls.scale) {
      this.previewWalls.scale = this.previewWalls.scale * 100;
    }
    //初始化颜色
    this.changeColor(
      "--background-color-preview",
      this.previewWalls.backgroundColor
    );
    this.changeColor("--font-color-preview", this.previewWalls.fontColor);
  },
  methods: {
    beforeUpload(file) {
      this.fileImg = file.url;
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const isLt1M = file.size / 1024 / 1024 < 10;
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");
        const index = this.$refs.uploadRef.uploadFiles.findIndex(
          (e) => e.uid === file.uid
        );
        this.$refs.uploadRef.uploadFiles.splice(index, 1);
        this.fileImg = "";
        return false;
      }
      // // 可以限制图片的大小
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        const index = this.$refs.uploadRef.uploadFiles.findIndex(
          (e) => e.uid === file.uid
        );
        this.$refs.uploadRef.uploadFiles.splice(index, 1);
        this.fileImg = "";
      }
    },
    setLogoUpload(file) {
      console.log("file", file.file);
      const formData = new FormData();
      formData.append("Files", file.file);
      setLogoUpload(formData).then((res) => {
        this.previewWalls.logo = res.data;
        this.setAppWall();
        console.log("res", res.data);
      });
    },
    handleRemove(file) {
      const index = this.$refs.uploadRef.uploadFiles.findIndex(
        (e) => e.uid === file.uid
      );
      this.$refs.uploadRef.uploadFiles.splice(index, 1);
      setTimeout(() => {
        this.fileImg = "";
      }, 1000);
    },
    submitUpload() {
      this.$refs.uploadRef.submit();
    },
    //设置调查墙
    setAppWall(type) {
      this.previewWalls.appId = this.appId;
      const previewWalls = JSON.parse(JSON.stringify(this.previewWalls));
      previewWalls.scale = previewWalls.scale / 100;
      console.log(' previewWalls', previewWalls)
      setAppWall(previewWalls).then((res) => {
        console.log("设置调查墙", this.appKey, res);
        if (res.status === 200 && type === 1) {
          this.$message({
            type: "success",
            message: "保存成功!",
          });
        }
        localStorage.setItem(this.appKey, JSON.stringify(this.previewWalls));
      });
    },
    //开启最低
    changeIsMinimum() {
      if (this.previewWalls.minCpiEnabled) {
        this.getNewList();
      } else {
        this.questionnaireList.forEach((_, index, arrar) => {
          arrar[index].isShow = true;
        });
      }
    },
    //开启最低，遍历数组并赋值
    getNewList() {
      console.log("触发", "getNewList");
      this.questionnaireList.forEach((item, index, array) => {
        if (item.price < parseInt(this.previewWalls.minCpi)) {
          array[index].isShow = false;
        } else {
          array[index].isShow = true;
        }
      });
    },
    //设置输入框只能输入数字
    inputChange(type) {
      if (type === 1) {
        this.previewWalls.ratio = this.previewWalls.ratio.replace(
          /^(0+)|[^\d]+/g,
          ""
        );
      } else {
        this.previewWalls.minCpi = this.previewWalls.minCpi.replace(
          /^(0+)|[^\d]+/g,
          ""
        );
        if (this.previewWalls.minCpiEnabled) this.getNewList();
      }
    },
    //改变颜色
    changeColor(dom, color) {
      document.documentElement.style.setProperty(dom, color);
    },
    //获取调查墙预览信息设置
    getAvailableSurveys() {
      const params = {
        app_id: this.appKey,
        user_id: 1,
      };
      getAvailableSurveys(params).then((res) => {
        if (res.status === 200) {
          this.questionnaireList = res.data;
          this.questionnaireList.forEach((_, index, array) => {
            array[index].isShow = true;
          });
        }
        console.log("this.questionnaireList", this.questionnaireList);
      });
    },
    //分配比例限制
    changeScale() {
      if (!this.previewWalls.scale) {
        return;
      }
      this.previewWalls.scale = this.previewWalls.scale + "";
      this.previewWalls.scale = this.previewWalls.scale.replace(
        /^(0+)|[^\d]+/g,
        ""
      );
      if (this.previewWalls.scale) {
        this.previewWalls.scale = parseInt(this.previewWalls.scale);
        if (this.previewWalls.scale > 100) {
          this.previewWalls.scale = 100;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:root {
  --background-color-preview: "";
  --font-color-preview: "";
}
$background-preview: var(--background-color-preview);
$font-preview: var(--font-color-preview);
.preview-main {
  display: flex;
  .setup-content {
    width: calc(50% - 1.5px);
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    background-color: #fff;
  }
  .preview-content {
    width: calc(50% - 1.5px);
    border: 1px solid #dddddd;
    border-radius: 0 4px 4px 0;
    border-left: none;
    background-color: #fff;
    overflow-y: auto;
    // max-height: 1208px;
    position: relative;
  }
}
.setup-content {
  color: #666;
  padding: 22px;
  .title {
    color: #06052b;
    font-weight: bold;
    margin-top: 2px;
    height: 20px;
    line-height: 20px;
  }
  .illustrate {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
    .tips {
      width: 12px;
      height: 12px;
      margin-left: 10px;
    }
  }
  .el-input {
    margin-top: 20px;
    /deep/ .el-input__inner {
      height: 36px;
    }
  }
  .exchange {
    .exchange-title {
      display: flex;
      font-size: 14px;
      margin-top: 50px;
      .bold {
        font-weight: bold;
      }
      .tips {
        width: 12px;
        height: 12px;
        margin-left: 10px;
      }
    }
    .haunsuan {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;
      font-size: 14px;
      .el-input {
        width: 73px;
        margin-top: 0;
        margin-left: 5px;
        /deep/ .el-input__inner {
          height: 30px;
        }
      }
    }
  }
  .line {
    border-top: 1px solid #f2f2f2;
    margin-top: 25px;
  }
  .minimum {
    margin-top: 50px;
    .minimum-title {
      display: flex;
      justify-content: space-between;
      div {
        font-weight: bold;
        font-size: 14px;
      }
    }
    .haunsuan {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;
      font-size: 14px;
      .el-input {
        width: 73px;
        margin-top: 0;
        margin-left: 20px;
        /deep/ .el-input__inner {
          height: 30px;
        }
      }
    }
  }
  .difficulty {
    display: flex;
    justify-content: space-between;
    margin-top: 42px;
    div {
      font-weight: bold;
      font-size: 14px;
    }
  }
  .save {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 231px;
    height: 40px;
    background: #4578ff;
    border-radius: 20px 20px 20px 20px;
    color: #fff;
    margin-top: 26px;
  }
  .straight {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    border-top: 1px solid #f2f2f2;
    margin-top: 76px;
    margin-bottom: 38px;
  }
  .upload {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 84px;
    height: 34px;
    background: #4578ff;
    padding: 0;
    margin-top: 38px;
    margin-bottom: 40px;
    color: #fff;
    border-radius: 4px 4px 4px 4px;
  }
  .bg-color {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 21px;
    .bg-title {
      font-size: 14px;
      color: #06052b;
    }
  }
  .font-color {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 61px;
    .font-title {
      font-size: 14px;
      color: #06052b;
    }
  }
}
.card-upload {
  display: flex;
  justify-content: center;
  margin-top: 37px;
  /deep/ .el-upload--picture-card {
    border-color: #ccc;
    width: 331px;
    height: 166px;
  }
  /deep/ .el-upload-list__item {
    width: 331px;
    height: 166px;
  }
  /deep/ .default {
    line-height: 20px;
    color: #dddddd;
    font-size: 14px;
    margin-top: 63px;
    span {
      color: #4578ff;
    }
  }
}
.is-file-list {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}

// 预览
.preview-content {
  .title {
    color: #06052b;
    font-weight: bold;
    margin-top: 24px;
    margin-left: 16px;
    height: 20px;
    line-height: 20px;
  }
  .questionnaire-main {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 20px;
    height: calc(100% - 74px);
    background-color: $background-preview;
    overflow-y: auto;
  }
}
.questionnaire-main {
  .preview-logo {
    display: flex;
    justify-content: center;
    font-size: 22px;
    img {
      width: 66.2px;
      height: 33.2px;
      margin-top: 15px;
    }
  }
  .questionnaire-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
    div {
      margin-left: 10px;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 14px;
    }
  }
}
.questionnaire-list {
  display: flex;
  flex-wrap: wrap;
  .questionnaire-item {
    // width: 196px;
    width: calc(50% - 15px);
    margin-left: 10px;
    background-color: #fff;
    margin-top: 10px;
    border-radius: 4px;
    .number-maxPoints {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .number {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        min-width: 100px;
        padding-right: 10px;
        .identifier {
          font-size: 18px;
          font-weight: bold;
          margin-top: 15px;
          color: #06052b;
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          margin-top: 10px;
          margin-left: 0;
          white-space: nowrap;
          word-break: keep-all;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $font-preview;
        }
      }
      .maxPoints {
        min-width: 73px;
        height: 38px;
        line-height: 38px;
        background: #2e67ea;
        box-shadow: 0px 3px 8px 1px rgba(51, 106, 234, 0.55);
        border-radius: 4px 4px 4px 4px;
        margin-right: 11px;
        margin-bottom: 5px;
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        span {
          font-size: 12px;
          margin-left: 2px;
        }
      }
    }
    .facility-value {
      font-size: 12px;
      color: #999999;
      margin-top: 20px;
      margin-left: 10px;
      .progress-ga {
        display: flex;
        align-items: center;
        .progress-fa {
          width: 100px;
        }
        .percentage {
          font-size: 12px;
          color: #999999;
          margin-left: 3px;
        }
      }
    }
    .clock-join {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 18px;
      .clock-box {
        display: flex;
        align-items: center;
        margin-left: 10px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        span {
          color: #666666;
        }
      }
      .join {
        width: 80px;
        height: 28px;
        line-height: 28px;
        margin-right: 10px;
        border-radius: 15px 15px 15px 15px;
        opacity: 1;
        border: 1px solid #2e67ea;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #2e67ea;
      }
    }
  }
}
</style>